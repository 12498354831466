import * as React from 'react';
import * as styles from './popoutMenu.module.scss';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { links } from '../../Footer/Footer';
import animation_light from './x_anim_light.json';
import animation_dark from './x_anim_dark_.json';
import Lottie from 'react-lottie';
import { Location } from '@reach/router';

interface Props {
  light: boolean;
  location?: { pathname: string };
}
interface State {
  menuActive: boolean;
  stopped: boolean;
}

class PopoutMenuComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuActive: false,
      stopped: true,
    };
  }

  menuClicked = () => {
    this.setState({
      menuActive: !this.state.menuActive,
      stopped: false,
    });
  };

  menuItemClicked = (item: String) => {
    if (
      this.props.location &&
      this.props.location.pathname.replace(/\//g, '') ===
        // Need to replace forward slash from item as well to account for the index page '/'
        item.replace(/\//g, '')
    ) {
      this.setState({ menuActive: false });
    }
  };

  render() {
    const bodymovinOptions = {
      animationData: this.props.light ? animation_light : animation_dark,
      prerender: true,
      autoplay: false,
      loop: false,
    };
    return (
      <div className={styles.menu}>
        <div
          onClick={this.menuClicked}
          className={classnames(
            styles.menuButton,
            !this.props.light ? styles.lightButton : '',
          )}
        >
          {typeof window !== 'undefined' && (
            <Lottie
              options={bodymovinOptions}
              isStopped={this.state.stopped}
              direction={this.state.menuActive ? 1 : -1}
            />
          )}
        </div>
        <div
          className={classnames(
            styles.menuContainer,
            this.state.menuActive ? styles.menuActive : '',
          )}
        >
          <div className={styles.menuWrapper}>
            <div className={styles.menuContent}>
              <Link
                onClick={() => this.menuItemClicked('/')}
                key="home"
                className={styles.link}
                to={`/`}
              >
                Home
              </Link>
              {links.map(link => (
                <Link
                  onClick={() => this.menuItemClicked(link)}
                  key={link}
                  className={styles.link}
                  to={`/${link}`}
                >
                  {link}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (props: Props) => (
  <Location>
    {locationProps => <PopoutMenuComponent {...locationProps} {...props} />}
  </Location>
);
