import * as React from 'react';
import * as styles from './header.module.scss';
import PopoutMenu from './PopoutMenu';
import Logo from '../../images/logo.inline.svg';
import { Link } from 'gatsby';
import classnames from 'classnames';

// const SVGLogoDark = logoDark;
// const SVGLogoLight = logoDark;

export default ({
  background = true,
  light = false,
  alternateMenuTheme = false,
}: {
  background?: boolean;
  light?: boolean;
  alternateMenuTheme?: boolean;
}) => (
  <div
    className={classnames(
      styles.headerContainer,
      background ? styles.filled : '',
      light ? styles.light : styles.dark,
    )}
  >
    <div className={styles.header}>
      <Link to={'/'}>
        <div
          className={classnames(
            styles.logo,
            light ? styles.lightLogo : styles.darkLogo,
          )}
        >
          <Logo />
        </div>
      </Link>
      <PopoutMenu light={alternateMenuTheme ? !light : light} />
    </div>
  </div>
);
