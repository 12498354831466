import * as React from 'react';
import * as styles from './footer.module.scss';

import { Link } from 'gatsby';

import classnames from 'classnames';

import facebookImage from './images/facebook.inline.svg';
import linkedinImage from './images/linkedin.inline.svg';
import twitterImage from './images/twitter.inline.svg';
import googleImage from './images/google.inline.svg';

export const links = [`companies`, `about`, `team`, `contact`];
const socials = [
  // {
  //   path: `https://facebook.com`,
  //   image: facebookImage,
  // },
  {
    path: `https://www.linkedin.com/company/primera-capital`,
    image: linkedinImage,
  },
  // {
  //   path: `https://twitter.com/primeracapital`,
  //   image: twitterImage,
  // },
  // {
  //   path: `https://google.com`,
  //   image: googleImage,
  // },
];

export default ({ light }: { light?: boolean }) => (
  <div className={classnames(styles.container, light ? styles.light : '')}>
    <div className={styles.links}>
      {links.map(link => (
        <Link key={link.toString()} className={styles.link} to={`/${link}`}>
          {link}
        </Link>
      ))}
    </div>
    <div className={styles.socialContainer}>
      {socials.map(social => {
        const Icon = social.image;
        return (
          <a
            key={social.path}
            href={social.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.socialIcon}>
              <Icon />
            </div>
          </a>
        );
      })}
    </div>
    <p className={styles.copyright}>
      Primera Capital © {new Date().getFullYear()}
    </p>
  </div>
);
