/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import 'normalize.css';
import '../scss/global.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const layout = ({ children }: { children: any }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return <>{children}</>;
};

layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default layout;
