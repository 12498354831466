/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
// tslint:disable-next-line: import-name
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import siteConfig from '../config/siteConfig';

import favicon32 from '../images/favicons/favicon-32x32.png';
import favicon16 from '../images/favicons/favicon-16x16.png';
import faviconTouch from '../images/favicons/apple-touch-icon.png';

interface Props {
  pageTitle: string;
  description?: string;
  title?: string;
}

const SEO = (props: Props) => {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            language
            ogLanguage
            siteUrl
          }
        }
        file(relativePath: { eq: "preview_image.jpg" }) {
          childImageSharp {
            fixed(width: 2520) {
              src
            }
          }
        }
      }
    `,
  );
  const { description, title, pageTitle } = props;

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': '',
      url: 'https://www.primeracapital.com',
      name: `Primera Capital`,
    },
  ];

  return (
    <Helmet>
      <html lang={site.siteMetadata.language} />
      <title>
        {pageTitle} | {metaTitle}
      </title>
      <meta name="description" content={metaDescription} />
      <meta
        name="image"
        content={`${site.siteMetadata.siteUrl}${file.childImageSharp.fixed.src}`}
      />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* <link
        href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.2.0/mapbox-gl.css"
        rel="stylesheet"
      /> */}

      <link rel="apple-touch-icon" sizes="180x180" href={faviconTouch} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <meta property="og:locale" content={site.siteMetadata.ogLanguage} />
      <meta property="og:site_name" content={metaTitle} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}${file.childImageSharp.fixed.src}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={site.siteMetadata.siteUrl} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={`${site.siteMetadata.siteUrl}${file.childImageSharp.fixed.src}`}
      />
    </Helmet>
  );
};

export default SEO;
